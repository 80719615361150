.intercom-article-content {
  @apply oneinch-text-regular-16;
}
.intercom-article-content p {
  min-height: 1.53em;
}
.intercom-article-content hr {
  display: block;
  height: 3px;
  border-radius: 3px;
  border: none;
  margin: 1em 0;
}
.intercom-article-content h1,
.intercom-article-content h2,
.intercom-article-content h3,
.intercom-article-content h4 {
  @apply oneinch-text-heading-h3 mb-4;
}
.intercom-article-content ul {
  @apply ml-4 mt-4 list-disc;
}
.intercom-article-content pre {
  white-space: pre-wrap;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiIiwic291cmNlcyI6WyJmaWxlOi8vL2hvbWUvcnVubmVyL3dvcmsvZGV2LXBvcnRhbC9kZXYtcG9ydGFsL2FwcHMvcG9ydGFsLXdlYmFwcC9zcmMvc3R5bGVzL2ludGVyY29tLnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRTs7QUFFQTtFQUNFOztBQUdGO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7RUFDQTs7QUFHRjtBQUFBO0FBQUE7QUFBQTtFQUlFOztBQUdGO0VBQ0U7O0FBR0Y7RUFDRSIsInNvdXJjZXNDb250ZW50IjpbIi5pbnRlcmNvbS1hcnRpY2xlLWNvbnRlbnQge1xuICBAYXBwbHkgb25laW5jaC10ZXh0LXJlZ3VsYXItMTY7XG5cbiAgcCB7XG4gICAgbWluLWhlaWdodDogMS41M2VtO1xuICB9XG5cbiAgaHIge1xuICAgIGRpc3BsYXk6IGJsb2NrO1xuICAgIGhlaWdodDogM3B4O1xuICAgIGJvcmRlci1yYWRpdXM6IDNweDtcbiAgICBib3JkZXI6IG5vbmU7XG4gICAgbWFyZ2luOiAxZW0gMDtcbiAgfVxuXG4gIGgxLFxuICBoMixcbiAgaDMsXG4gIGg0IHtcbiAgICBAYXBwbHkgb25laW5jaC10ZXh0LWhlYWRpbmctaDMgbWItNDtcbiAgfVxuXG4gIHVsIHtcbiAgICBAYXBwbHkgbWwtNCBtdC00IGxpc3QtZGlzYztcbiAgfVxuXG4gIHByZSB7XG4gICAgd2hpdGUtc3BhY2U6IHByZS13cmFwO1xuICB9XG59XG4iXX0= */